export const radioFieldOptions = [
    {
        label: 'False',
        value: 'false',
    },
    {
        label: 'True',
        value: 'true',
    },
];
