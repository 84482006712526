import React, { useState } from 'react';
import { HocOptions } from 'components/common/generic-hoc.types';
import GenericHoc from 'components/common/generic-hoc';
import {
    Modal,
    Form,
    Input,
    Select,
    Row,
    Col,
    Button,
    Progress,
    Radio,
    message,
} from 'antd';
import onboardIcon from '../../assets/onboard-from-icon.png';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { onboardCustomer } from '../../network/common.api';
import { radioFieldOptions } from './custom-form-modal.constants';

const styles = (theme: ThemeType) => ({
    modalStyle: {
        borderRadius: '12px',
        padding: '0px',
        '& .ant-modal-content': {
            borderRadius: '15px',
        },
        '& .ant-modal-content > .ant-modal-footer': {
            borderRadius: '15px',
        },
    },
    headerStyle: {
        background: '#f5f5f5',
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        borderRadius: '12px',
        padding: '8px 0px 5px 24px',
        margin: '-24px -24px 10px -24px',
    },
    formItem: {
        marginBottom: '8px',
        '& .ant-form-item-label': {
            paddingBottom: '2px',
        },
    },
    inputItem: {
        paddingBottom: '2px',
        borderRadius: '10px',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    progreeBarLine: {
        display: 'flex',
        '& .ant-progress-line': {
            width: '68%',
            marginRight: '10px',
        },
    },
    errorMessage: {
        backgroundColor: '#fdecea',
        color: '#d93025',
        padding: '5px',
        borderRadius: '5px',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    logoStyle: {
        flexBasis: '60%',
        display: 'flex',
    },
    logoIcon: {
        width: 40,
        height: 40,
        margin: '8px 8px 0px 0px',
    },
    subHeading: {
        fontSize: 14,
        color: '#888',
    },
    progressBarStyle: {
        flexBasis: '35%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '15px',
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>> {
    params: any;
}

const OnboardingModal = (props: IProps) => {
    const {
        classes,
        params,
    } = props;
    const { formMetadata } = params;
    const isSkippable = formMetadata?.is_skippable || false;
    const [currentPage, setCurrentPage] = useState(0);
    const [modalHeight, setModalHeight] = useState(400);
    const [formValues, setFormValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [pageName, setPageName] = React.useState('');

    const { pageList = {} } = formMetadata;
    const [form] = Form.useForm();
    const totalPages = pageList?.length;
    const progressPercent = ((currentPage + 1) / totalPages) * 100;

    React.useEffect(() => {
        // Calculate the height based on metadata
        const maxHeight = formMetadata?.pageList?.reduce((max: number, page: any) => {
            const pageHeight = page?.sectionList?.reduce((sectionHeight: number, section: any) => {
                return sectionHeight + section?.fieldList?.length * 160;
            }, 0);
            return Math.max(max, pageHeight);
        }, 0);

        if (maxHeight < 400) {
            setModalHeight(maxHeight);
        }
        if (pageList.length) {
            setPageName(pageList[currentPage]?.prettyName);
        }
    }, []);

    const handleSave = async () => {
        await form?.validateFields();
        const data = form?.getFieldsValue();
        const updatedFormValues = { ...formValues, ...data };
        setLoading(true);
        setErrorMessage('');
        const response = await onboardCustomer(updatedFormValues);
        if (response?.isSuccess) {
            setIsVisible(false);
            message.success('Onboarding Successful!');
        } else {
            setErrorMessage(response.errorMessage || 'Onbooarding failed, Please retry');
        }
        setLoading(false);
    };
    const handlePrev = () => {
        if (currentPage > 0) {
            const values = form.getFieldsValue();
            setFormValues((prev) => ({ ...prev, ...values }));
            form.setFieldsValue(formValues);
            setCurrentPage((prev) => prev - 1);
            setPageName(pageList[currentPage - 1]?.prettyName);
        }
    };
    const handleNext = async () => {
        await form?.validateFields();
        const values = form?.getFieldsValue();
        setFormValues((prev) => ({ ...prev, ...values }));
        if (currentPage < pageList?.length - 1) {
            setCurrentPage((prev) => prev + 1);
            if (pageList.length > (currentPage + 1)) {
                setPageName(pageList[currentPage + 1]?.prettyName);
            }
        } else {
            handleSave();
        }
    };
    const handleCancel = () => {
        form.setFieldsValue({});
        setFormValues({});
        setIsVisible(false);
    };

    const renderField = ((field: any) => {
        switch (field?.dataType) {
            case 'boolean':
                return (
                    <Radio.Group
                        className={classes.inputItem}
                        defaultValue={false}
                        options={radioFieldOptions}
                    />
                );

            case 'string':
                return (
                    <Input
                        placeholder={`Enter ${field?.prettyName}`}
                        className={classes.inputItem}
                    />
                );

            case 'number':
                return (
                    <Input
                        type="number"
                        placeholder={`Enter ${field?.prettyName}`}
                        className={classes.inputItem}
                    />
                );

            case 'dropdown':
                return (
                    <Select
                        placeholder={`Select ${field.prettyName}`}
                        className={classes.inputItem}
                    >
                        {field?.values?.map((option: any) => (
                            <Select.Option key={option} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                );

            default:
                return null;
        }
    });

    const renderFooter = () => {
        const prevBtn = currentPage > 0
            && (
                <Button
                    style={{ borderRadius: '4px' }}
                    loading={loading}
                    type="primary"
                    onClick={handlePrev}
                >
                    Prev
                </Button>
            );

        const nextBtn = (
            <Button style={{ borderRadius: '4px' }} loading={loading} type="primary" onClick={handleNext}>
                {currentPage < pageList?.length - 1 ? 'Next' : 'Submit'}
            </Button>
        );
        return [
            prevBtn, nextBtn,
        ];
    };

    return (
        <div>
            <Modal
                visible={isVisible}
                centered
                onCancel={handleCancel}
                footer={renderFooter()}
                closable={isSkippable}
                maskClosable={false}
                className={classes.modalStyle}
                bodyStyle={{ borderRadius: '12px' }}
            >
                <div className={classes.headerStyle}>
                    <div className={classes.logoStyle}>
                        <img
                            src={onboardIcon}
                            alt="Customer Portal Icon"
                            className={classes.logoIcon}
                        />
                        <div>
                            <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                                Welcome to Customer Portal
                            </div>
                            <div className={classes.subHeading}>Fill up some details to get started</div>
                        </div>
                    </div>
                    <div className={classes.progressBarStyle}>
                        <p style={{ margin: '0px' }}>{pageName}</p>
                        <div className={classes.progreeBarLine}>
                            <Progress
                                percent={progressPercent}
                                showInfo={false}
                                style={{ marginBottom: '5px' }}
                            />
                            <p>{`${currentPage + 1} / ${totalPages}`}</p>
                        </div>
                    </div>
                </div>

                <Form form={form} layout="vertical" style={{ maxHeight: `${modalHeight}px`, overflow: 'auto' }}>
                    {pageList[currentPage]?.sectionList?.map((section: any) => (
                        <div style={{ marginBottom: '10px' }}>
                            <h3 style={{ fontWeight: '600' }}>{section?.title}</h3>
                            <Row gutter={[8, 2]}>
                                {section?.fieldList?.map((field: any) => {
                                    if (!field?.enable) return null;
                                    return (
                                        <Col key={field?.id} xs={24} sm={12}>
                                            <Form.Item
                                                label={field?.prettyName}
                                                name={field?.id}
                                                className={classes.formItem}
                                                rules={[
                                                    {
                                                        required: !field?.isOptional,
                                                        message: `${field?.prettyName} is required`,
                                                    },
                                                ]}
                                            >
                                                {renderField(field)}
                                            </Form.Item>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    ))}
                </Form>
                {errorMessage && (
                    <div className={classes.errorMessage}>
                        <i className="icon-warning" />
                        {errorMessage}
                    </div>
                )}
            </Modal>
        </div>
    );
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};
export default GenericHoc(hocConfig)(OnboardingModal);
