import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Select,
    Tooltip,
} from 'antd';
import GenericField from '../generic-field';

const styles = () => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        width: 250,
        alignItems: 'left',
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    customTooltip: {
        '& .ant-tooltip-inner': {
            color: '#333',
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>> {
    form: FormInstance;
    params: any;
}

const ProductCodeField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        params,
    } = props;
    const {
        PieceProductCode,
    } = formFields;
    const {
        key,
        pieceIndex,
        productCodeLists,
        showProductCodeDropdown,
        productHsCodeMapping,
    } = params;

    const [options, setOptions] = React.useState<any[]>([]);
    React.useEffect(() => {
        const list = productCodeLists[pieceIndex] || [];
        setOptions(list.map((item: any) => ({
            label: item,
            value: item,
        })));
    }, [productCodeLists]);

    const handleProductCodeChange = (value: any) => {
        const mapping = productHsCodeMapping[pieceIndex] || {};
        const requiredHsCode = mapping[value];
        if (requiredHsCode) {
            props?.form.setFieldsValue({
                [`hsCode_${pieceIndex}`]: requiredHsCode,
            });
        }
    };

    return (
        <Col span={12}>
            <div style={{ display: 'flex' }}>
                {showProductCodeDropdown
                    ? (
                        <Tooltip
                            title={!options.length ? 'Please validate Purchase Order Number' : ''}
                            overlayClassName={classes.customTooltip}
                            color="#FCFCFC"
                        >
                            <div className={classes.formItem}>
                                <div
                                    className={classes.title}
                                    style={{ width: PieceProductCode.width }}
                                >
                                    {t('pieceProductCode')}
                                </div>
                                <Form.Item
                                    name={key}
                                    initialValue={PieceProductCode.defaultValue}
                                    valuePropName={PieceProductCode.valuePropName}
                                >
                                    <Select
                                        showSearch
                                        disabled={!options.length}
                                        placeholder={t(PieceProductCode.key)}
                                        options={options}
                                        allowClear
                                        onChange={handleProductCodeChange}
                                    />
                                </Form.Item>
                            </div>
                        </Tooltip>
                    )
                    : <GenericField field={PieceProductCode} {...props} />}
            </div>
        </Col>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(ProductCodeField);
