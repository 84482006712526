import { API_BASE_URL, IFRAME_NON_STRICT_MODE } from '../../library/globals';
import { AppMetadata, OutgoingActions } from './persistent-iframe.constants';

const allMessageQueues: Record<string, any> = {};
const isAppLoaded: Record<string, boolean> = {};

const sendMessageToIframeWindow = (appName: string, message: string) => {
    const frameId = AppMetadata[appName].iframeId;
    const origin = IFRAME_NON_STRICT_MODE ? '*' : AppMetadata[appName].origin;
    (window.frames[frameId as any] as any).contentWindow.postMessage(message, origin);
};

const checkIfAppLoaded = (appName: string) => {
    return isAppLoaded[appName] || false;
};

export const setAppLoaded = (appName: string) => {
    isAppLoaded[appName] = true;
};

export const sendMessage = (appName: string, message: string) => {
    if (checkIfAppLoaded(appName)) {
        sendMessageToIframeWindow(appName, message);
    } else {
        allMessageQueues[appName] = (allMessageQueues[appName] || []).concat(message);
    }
};

export const flushMessageQueue = (appName: string) => {
    const queue = allMessageQueues[appName] || [];
    queue.forEach((message: string) => {
        sendMessageToIframeWindow(appName, message);
    });
};

export const sendRoutingEvent = (appName: string, route: string) => {
    const stringifiedRoute = JSON.stringify({
        type: OutgoingActions.Routing,
        data: route,
    });
    sendMessage(appName, stringifiedRoute);
};

export const sendCredentials = (appName: string, credentials: any) => {
    const apiBaseUrl = new URL(API_BASE_URL);
    const stringifiedCredentials = JSON.stringify({
        type: OutgoingActions.CREDENTIALS_SHARING,
        data: {
            ...credentials,
            'parent-url': window.location.href,
            'project-name': 'customer-logistics',
            'api-base-url': apiBaseUrl.hostname,
        },
    });
    sendMessage(appName, stringifiedCredentials);
};
