import moment from 'moment';
import { FormField, InputTypes } from '../../create-consignment/create-consignment.types';

export const pickupFormFields: Record<string, FormField> = {
    PickupSchedule: {
        label: 'Pickup Schedule',
        type: InputTypes.Date,
        key: 'pickupSchedule',
        defaultValue: moment(),
        valuePropName: 'value',
        direction: 'column',
        width: 200,
        placeholder: 'Select Pickup Schedule',
    },
    WeightUnit: {
        label: 'Unit',
        type: InputTypes.Select,
        key: 'weightUnit',
        defaultValue: 'kg',
        valuePropName: 'value',
        placeholder: 'Unit',
        width: 50,
        options: [{
            label: 'kg',
            value: 'kg',
        }, {
            label: 'gm',
            value: 'gm',
        }],
    },
    DeclaredValue: {
        label: 'Declared Value*',
        type: InputTypes.Input,
        key: 'declaredValue',
        valuePropName: 'value',
        placeholder: 'Declared Value*',
        width: 150,
        titleWidth: '20%',
    },
    PickupTime: {
        label: 'Pickup Time Slot',
        type: InputTypes.Time,
        key: 'pickupTime',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Select Time',
        width: 200,
    },
    ConsignmentNumber: {
        label: 'Reference Number',
        type: InputTypes.Tag,
        key: 'referenceNumber',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Enter Consignment No.',
        width: 350,
    },
    ServiceType: {
        label: 'Service Type',
        type: InputTypes.Select,
        key: 'serviceType',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Select Service',
        width: 250,
        required: true,
    },
    Remarks: {
        label: 'Remarks',
        type: InputTypes.Textarea,
        key: 'remarks',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Enter Remarks',
        width: 125,
    },
    PickupTimeStart: {
        label: 'Pickup Time Slot Start',
        type: InputTypes.CustomTime,
        key: 'pickupTimeStart',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Select Time',
        width: 200,
        required: true,
    },
    PickupTimeEnd: {
        label: 'Pickup Time Slot End',
        type: InputTypes.CustomTime,
        key: 'pickupTimeEnd',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Select Time',
        width: 200,
        required: true,
    },
    PickupType: {
        label: 'Pickup Type',
        type: InputTypes.Radio,
        key: 'pickupType',
        direction: 'row',
        defaultValue: 'Open',
        valuePropName: 'value',
        options: [{
            label: 'Open',
            value: 'open',
        }, {
            label: 'Consignment Specific',
            value: 'consignment_specific',
        }],
    },
    ItemType: {
        label: 'Item Type',
        type: InputTypes.Radio,
        key: 'courierType',
        direction: 'row',
        defaultValue: 'non-document',
        valuePropName: 'value',
        options: [{
            label: 'Document',
            value: 'document',
        }, {
            label: 'Non Document',
            value: 'non-document',
        }],
    },
    NumPieces: {
        label: 'Total Items (in numbers)*',
        type: InputTypes.Input,
        key: 'totalItems',
        valuePropName: 'value',
        placeholder: 'No. of Pieces',
        width: 150,
    },
    Quantity: {
        label: 'Quantity',
        type: InputTypes.Input,
        key: 'quantity',
        valuePropName: 'value',
        placeholder: 'No. of Pieces',
        width: 150,
    },
    Weight: {
        label: 'Weight*',
        type: InputTypes.Input,
        key: 'totalWeight',
        valuePropName: 'value',
        placeholder: 'Weight',
        width: 150,
    },
    Volume: {
        label: 'Volume(m³)*',
        type: InputTypes.Input,
        key: 'totalVolume',
        valuePropName: 'value',
        placeholder: 'Volume',
        width: 150,
    },
    WaybillType: {
        label: 'Waybill Type',
        type: InputTypes.Radio,
        key: 'waybillType',
        valuePropName: 'value',
        options: [{
            label: 'Default',
            value: 'Default',
        }, {
            label: 'Preprinted',
            value: 'Preprinted',
        }],
        width: 150,
        direction: 'row',
        defaultValue: 'Default',
    },
    AddressOption: {
        label: '',
        type: InputTypes.Radio,
        key: 'adressOption',
        direction: 'row',
        defaultValue: 'saved',
        valuePropName: 'value',
        width: '100%',
        options: [{
            label: 'From Saved Address',
            value: 'saved',
        }, {
            label: 'Add/Edit Address',
            value: 'createnew',
        }],
    },
    AddressLine: {
        label: '',
        type: InputTypes.Checkbox,
        key: 'pickupAddress',
        valuePropName: 'checked',
    },
    Length: {
        label: 'Length',
        type: InputTypes.Input,
        key: 'length',
        valuePropName: 'value',
        placeholder: 'Length',
        width: 100,
        titleWidth: '25%',
    },
    Width: {
        label: 'Width',
        type: InputTypes.Input,
        key: 'width',
        valuePropName: 'value',
        placeholder: 'Width',
        width: 100,
    },
    Height: {
        label: 'Height',
        type: InputTypes.Input,
        key: 'height',
        valuePropName: 'value',
        placeholder: 'Height',
        width: 100,
    },
    ContentTypes: {
        label: 'Content Type *',
        type: InputTypes.Select,
        key: 'commodityId',
        valuePropName: 'value',
        placeholder: 'Content Type',
        width: 150,
    },
    Unit: {
        label: 'Unit',
        type: InputTypes.Select,
        key: 'unit',
        defaultValue: 'cm',
        valuePropName: 'value',
        placeholder: 'Unit',
        width: 70,
        options: [{
            label: 'Cm',
            value: 'cm',
        }, {
            label: 'Inch',
            value: 'inch',
        }, {
            label: 'Ft',
            value: 'ft',
        }, {
            label: 'Mtr',
            value: 'mtr',
        }],
    },
};
