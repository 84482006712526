import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    Input,
    Radio,
    Select,
} from 'antd';
import { formRules } from 'library/constants';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
}

const CustomReferenceNumberField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const { classes, params } = props;
    const {
        isRequired,
        currentCustomField,
        id: pieceIndex,
        key,
    } = params;
    const currentField = currentCustomField[0];
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const renderTitle = (fieldData: any) => {
        return (
            <div
                className={classes.title}
            >
                {isPieceLevel ? t(`Piece ${fieldData.prettyName}`) : t(fieldData.prettyName)}
            </div>
        );
    };

    const renderInput = (fieldData: any) => {
        return (
            <div className={classes.referenceItem}>
                <Form.Item
                    name={isPieceLevel ? key : fieldData.id}
                    className={classes.contentType}
                    valuePropName="value"
                    rules={isRequired(false, currentField.id) ? [formRuleRequired] : undefined}
                >
                    <Input
                        type={fieldData.inputType || 'text'}
                        placeholder={t('enter_value')}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderBoolean = (fieldData: any) => {
        return (
            <Form.Item
                name={isPieceLevel ? key : fieldData.id}
                valuePropName={fieldData.valuePropName}
                initialValue="false"
            >
                <Radio.Group
                    options={[{ value: 'false', label: t('false') }, { value: 'true', label: t('true') }]}
                />
            </Form.Item>
        );
    };

    const renderDropdown = (fieldData: any) => {
        if (!(fieldData?.formUIComponent && fieldData?.formUIComponent?.type === 'dropdown')) {
            return <></>;
        }
        const options = fieldData?.formUIComponent?.values;
        if (Array.isArray(options) && options.length) {
            const isMultiple = fieldData?.formUIComponent?.multiselect ? { mode: 'multiple' } : {};
            return (
                <Form.Item
                    name={isPieceLevel ? key : fieldData.id}
                    initialValue={fieldData.defaultValue}
                    valuePropName={fieldData.valuePropName}
                    rules={isRequired(false, currentField.key) ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={t('select_values')}
                        options={options}
                        {...isMultiple}
                        allowClear
                    />
                </Form.Item>
            );
        }
        return <></>;
    };

    const renderFormItem = (
        fieldData: any,
    ) => {
        let dataType = fieldData?.formUIComponent?.type;
        if (!(dataType && dataType === 'dropdown')) {
            dataType = fieldData?.dataType;
        }
        switch (dataType) {
            case 'string': return renderInput(fieldData);
            case 'number': return renderInput({
                ...fieldData,
                inputType: 'number',
            });
            case 'boolean': return renderBoolean(fieldData);
            case 'dropdown': return renderDropdown(fieldData);
            default: return 'Input is not defined';
        }
    };

    return (
        <Col span={12}>
            <div className={classes.formItem}>
                {renderTitle(currentField)}
                {renderFormItem(currentField)}
            </div>
        </Col>
    );
};

const mapStateToProps = () => {
    return {};
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(CustomReferenceNumberField);
