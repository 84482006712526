import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { FormField } from 'components/create-consignment/create-consignment.types';
import { dangerousGoodFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import { formRules } from 'library/constants';
import { StylesProps } from 'theme/jss-types';
import {
    getDangerousGoodData,
} from '../../../../network/consignments.api';

import {
    Col,
    Form,
    Input,
    message,
    Row,
    FormInstance,
} from 'antd';

const styles = () => ({
    wrapper: {
        gap: '10px',
        margin: '5px',
    },
    colWrapper: {
        flexBasis: '49%',
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}
const DangerousGoodFields = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        form,
        params,
    } = props;

    const { pieceIndex } = params;
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;
    const {
        DangerousGoodUnNumber,
        DangerousGoodName,
        DangerousGoodClass,
        DangerousGoodDivision,
    } = dangerousGoodFields;

    const dangerousDivisionValue = form.getFieldValue([isPieceLevel ? `${DangerousGoodDivision.key}_${pieceIndex}`
        : DangerousGoodDivision.key]);
    const dangerousClassValue = form.getFieldValue([isPieceLevel ? `${DangerousGoodClass.key}_${pieceIndex}`
        : DangerousGoodClass.key]);
    const dangerousNameValue = form.getFieldValue([isPieceLevel ? `${DangerousGoodName.key}_${pieceIndex}`
        : DangerousGoodName.key]);
    const [dangerousDivision, setDangerousDivision] = React.useState<any>(dangerousDivisionValue);
    const [dangerousClass, setDangerousClass] = React.useState<any>(dangerousClassValue);
    const [dangerousName, setDangerousName] = React.useState<any>(dangerousNameValue);

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const validateRegex = (value: string) => {
        const regex = /^[Uu][Nn](?:[0-2]\d{3}|3[0-5]\d{2}|3600)$/;
        return regex.test(value);
    };

    const fetchUnNumberData = async (value: string) => {
        if (value && validateRegex(value)) {
            const unNumber = value?.toString()?.toUpperCase();
            const response = await getDangerousGoodData({ unNumber });
            if (response.isSuccess) {
                setDangerousClass(response?.data?.class);
                setDangerousDivision(response?.data?.division);
                setDangerousName(response?.data?.shipping_name);
                form.setFieldsValue({
                    [isPieceLevel ? `${DangerousGoodClass.key}_${pieceIndex}`
                        : DangerousGoodClass.key]: response?.data?.class,
                    [isPieceLevel ? `${DangerousGoodDivision.key}_${pieceIndex}`
                        : DangerousGoodDivision.key]: response?.data?.division,
                    [isPieceLevel ? `${DangerousGoodName.key}_${pieceIndex}`
                        : DangerousGoodName.key]: response?.data?.shipping_name,
                });
            } else {
                message.error(response?.errorMessage || 'Invalid input!');
            }
        } else {
            form.setFieldsValue({
                [isPieceLevel ? `${DangerousGoodClass.key}_${pieceIndex}`
                    : DangerousGoodClass.key]: undefined,
                [isPieceLevel ? `${DangerousGoodDivision.key}_${pieceIndex}`
                    : DangerousGoodDivision.key]: undefined,
                [isPieceLevel ? `${DangerousGoodName.key}_${pieceIndex}`
                    : DangerousGoodName.key]: undefined,
            });
            setDangerousClass(undefined);
            setDangerousDivision(undefined);
            setDangerousName(undefined);
        }
    };

    const renderField = (field: FormField, value: string | number | undefined) => {
        return (
            <Col span={12} className={classes.colWrapper}>
                <div>
                    <div
                        className={classes.title}
                        style={{ marginBottom: '5px' }}
                    >
                        {t(field.label)}
                    </div>
                    <Form.Item
                        name={isPieceLevel ? `${field.key}_${pieceIndex}` : field.key}
                        rules={field.validate ? [formRuleRequired] : undefined}
                    >
                        <Input
                            disabled
                            value={value}
                        />
                    </Form.Item>
                </div>
            </Col>
        );
    };

    return (
        <div style={{ minWidth: '100%' }}>
            <Row className={classes.wrapper}>
                <Col span={12} className={classes.colWrapper}>
                    <div>
                        <div
                            className={classes.title}
                            style={{ marginBottom: '5px' }}
                        >
                            {t(DangerousGoodUnNumber.label)}
                        </div>
                        <Form.Item
                            name={isPieceLevel ? `${DangerousGoodUnNumber.key}_${pieceIndex}` : DangerousGoodUnNumber.key}
                            initialValue={DangerousGoodUnNumber.defaultValue}
                            valuePropName={DangerousGoodUnNumber.valuePropName}
                            rules={[
                                {
                                    validator: (rule, value, cb) => {
                                        if (value && !validateRegex(value)) {
                                            return cb('UN Number can be from UN000 to UN3600');
                                        }
                                        return cb();
                                    },
                                }]}
                        >
                            <Input
                                placeholder={t(DangerousGoodUnNumber.key)}
                                onInput={(e) => {
                                    (e.target as HTMLInputElement).value = (e.target as HTMLInputElement)
                                        .value?.toUpperCase();
                                }}
                                onChange={(e) => fetchUnNumberData(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                </Col>
                {dangerousName && renderField(DangerousGoodName, dangerousName)}
                {dangerousClass && renderField(DangerousGoodClass, dangerousClass)}
                {dangerousDivision && renderField(DangerousGoodDivision, dangerousDivision)}
            </Row>
        </div>
    );
};

const mapStateToProps = () => {
    return {};
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(DangerousGoodFields);
